import React from 'react'
import { Router } from '@reach/router'
import Main from './scene/Main'
import './assets/tailwind.css'
import Success from './scene/Success'
import TrackingStand from './scene/TrackingStand'
import LoginPage from './scene/loginPage'
import TrackingAdminPage from './scene/TrackingAdminPage'

function AppRouter(props) {
  document.title = 'COVID-19 Emergency Medical Supplies'
  return (
    <Router>
      <Main path="/" default />
      <LoginPage path="login" />
      <TrackingStand path="/order/:trackingNumber" />
      <TrackingAdminPage path="/admin/order/:trackingNumber" />
      <Success path="/success/:type" />
    </Router>
  )
}

export default AppRouter
