import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from './AppRouter'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.css'
// import 'react-table-6/react-table.css'
import './assets/main.css'

ReactDOM.render(<AppRouter />, document.getElementById('root'))

serviceWorker.unregister()
