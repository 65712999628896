import React from 'react'
import useFetch from 'react-fetch-hook'
import { sumBy, get } from 'lodash'
import moment from 'moment'

function Stats(props) {
  const { data } = useFetch('https://api.covidnow.com/v1/global/stats')

  const confirmed = Number(get(data, 'data.total', 0).toFixed(2)).toLocaleString('en-US')
  const deaths = Number(get(data, 'data.deaths', 0).toFixed(2)).toLocaleString('en-US')
  const recovered = Number(get(data, 'data.recovered', 0).toFixed(2)).toLocaleString('en-US')
  const date = moment(get(data, 'ts')).format('YYYY-MM-DD')
  return (
    <div className="m-5  mx-auto flex flex-col p-6 bg-white rounded-lg shadow-xl">
      <h5 className="text-lg flex-1 leading-6 mb-5 font-medium text-gray-900">COVID-19 RESPONSE</h5>
      <div className="flex-1 flex text-center">
        <div className=" flex-1  ml-6 pt-1">
          <h4 className="text-xl text-red-600 font-bold">{confirmed || 'Not available'}</h4>
          <p className="text-base text-gray-600">TOTAL CONFIRMED</p>
        </div>

        <div className=" flex-1  ml-6 pt-1">
          <h4 className="text-xl text-gray-700 font-bold">{deaths || 'Not available'}</h4>
          <p className="text-base text-gray-600">TOTAL DEATHS</p>
        </div>

        <div className=" flex-1  ml-6 pt-1">
          <h4 className="text-xl text-green-700 font-bold">{recovered || 'Not available'}</h4>
          <p className="text-base text-gray-600">TOTAL RECOVERED</p>
        </div>
      </div>
      <div className="pt-8">
        <span className="text-sm flex-1 leading-none mb-5 font-medium text-gray-900">
          Data updated daily courtesy of:{' '}
          <a
            href={
              'https://gisanddata.maps.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6'
            }
            target="_blank"
            rel="noopener noreferrer">
            <u>Johns Hopkins University</u>
          </a>
        </span>
        <p className="text-sm flex-1 leading-none mb-5 font-medium text-gray-900">
          Data Last update: {date}
        </p>
      </div>
    </div>
  )
}

export default Stats
