import React from 'react'

function Footer(props) {
  return (
    <footer className="w-full bg-grey-lighter py-8">
      <div className="container mx-auto text-center px-8">
        <p className="text-grey-dark mb-2 text-sm">
          © Copyright 2020 <span className="font-bold">MONDIALE GROUP</span>
        </p>

        <p className="text-gray-500 text-xs">
          Company NO.: <span className="font-bold"> 201114646N</span>
          &nbsp;|&nbsp;
          DUNS NO.: <span className="font-bold"> 659200869</span>
        </p>
        <p className="text-gray-500 text-xs">
          ISO 9001 : <span className="font-bold"> 2015 Certified</span>
          &nbsp;|&nbsp;
          ISO 14001 : <span className="font-bold"> 2015 Certified</span>
          &nbsp;|&nbsp;
          OSHAS 18001 : <span className="font-bold"> 2007 Certified</span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
