import React, { useState } from 'react'
import { Modal } from 'antd'
import ProductCard from './ProductCard'

function ProductModal(props) {
  const { img, title, subTitle} = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="lg:w-1/6 md:w-1/4 sm:w-1">
      <span onClick={() => setIsOpen(true)}>
        <ProductCard {...{ img, title, subTitle }} />
      </span>

      <Modal title={title} visible={isOpen} footer={null} onCancel={() => setIsOpen(false)}>
        <div className="p-4 pt-12">
          <div
            className="bg-cover bg-center h-56 p-4 bg-contain bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${img})` }}
          />
        </div>
        {props.children}
      </Modal>
    </div>
  )
}

export default ProductModal
