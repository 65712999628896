import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Checkbox, Slider, Select, DatePicker, Spin, Tooltip } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import uniqid from 'uniqid'
import moment from 'moment'
import { get, upperFirst } from 'lodash'
import useFetch from '../modules/useFetch'
import err from '../img/undraw_empty_xct9.png'
import PageWrapper from './PageWrapper'
import { navigate } from '@reach/router'

export function LineItem(props) {
  console.log(props)
  const { date, location, status, messages = [], remove, id, isFirst } = props
  return (
    <tr className={isFirst && 'bg-gray-100'}>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        <div className="text-sm leading-5 text-gray-900">
          {moment(date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY hh:mm a')}
        </div>
        <div className="text-sm leading-5 text-gray-500">
          {moment(date, 'YYYY-MM-DD HH:mm').fromNow()}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        {location}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        <div className="text-sm leading-5 text-gray-900">{upperFirst(status)}</div>
        {messages.map((item) => (
          <div className="text-sm leading-5 text-gray-500" key={Math.random()}>
            {item}
          </div>
        ))}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
        <span
          onClick={() => remove(id)}
          className="text-red-600 hover:text-indigo-900 focus:outline-none focus:underline">
          remove
        </span>
      </td>
    </tr>
  )
}

function TrackingAdminPage({ trackingNumber }) {
  const { response: data, error, loading } = useFetch(`/order/${trackingNumber}`)
  const order = get(data, 'order.code')
  const client = get(data, 'client.fn')

  const [lines, setLines] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [formLine] = Form.useForm()
  const [form] = Form.useForm()
  const requireF = [{ required: true, message: ' This Field is Required!' }]

  async function onFinish(values) {
    const body = { ...values, list: lines }

    setSubmitting(true)

    const response = await fetch(`/api/order/${trackingNumber}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })

    setSubmitting(false)
    form.resetFields()
    navigate(`/admin/order/${trackingNumber}`)
    console.log(response)
  }

  const removeLine = (id) => setLines(lines.filter((i) => i.id !== id))
  const addLine = (values) => {
    formLine.resetFields()
    setLines([...lines, { ...values, id: uniqid() }])
  }

  useEffect(() => {
    form.setFieldsValue(data)
    setLines(get(data, 'list') || [])
  }, [loading, data])

  return (
    <PageWrapper>
      <Spin tip="Loading..." spinning={loading}>
        <div className="bg-white border-t border-b sm:rounded sm:border shadow">
          <div className="border-b">
            <div className="flex justify-between px-6 -mb-px">
              <h3 className="text-blue-dark py-4 font-normal text-lg">Admin Page Tracking Edit</h3>
            </div>
          </div>
          <div>
            <div className="text-center px-6 py-4">
              {get(data, 'status') === 'failed' ? (
                <span>
                  <img src={err} className="w-full" alt="a platter of cookies" />

                  <div className="px-6 py-4 text-center">
                    <div className="font-bold text-3xl mb-2">Sorry</div>
                    <div className="font-medium text-xl mb-2">The Tracking Number is Invalid.</div>
                    <div className="font-bold text-xl text-red-500 mb-2">
                      Tracking Number : {trackingNumber}
                    </div>
                    <p className="text-base text-gray-700">
                      Our Tracking system was unable to find this tracking number.
                    </p>
                  </div>
                </span>
              ) : (
                <div className="py-8">
                  <div className="mb-12">
                    <div className="mb-4">
                      <svg
                        className="inline-block fill-current text-grey h-16 w-16"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20">
                        {/*<path d="M11.933 13.069s7.059-5.094 6.276-10.924a.465.465 0 0 0-.112-.268.436.436 0 0 0-.263-.115C12.137.961 7.16 8.184 7.16 8.184c-4.318-.517-4.004.344-5.974 5.076-.377.902.234 1.213.904.959l2.148-.811 2.59 2.648-.793 2.199c-.248.686.055 1.311.938.926 4.624-2.016 5.466-1.694 4.96-6.112zm1.009-5.916a1.594 1.594 0 0 1 0-2.217 1.509 1.509 0 0 1 2.166 0 1.594 1.594 0 0 1 0 2.217 1.509 1.509 0 0 1-2.166 0z" />*/}
                        <path d="M11.174,14.993h1.647c0.228,0,0.412-0.184,0.412-0.411v-1.648c0-0.228-0.185-0.411-0.412-0.411h-1.647c-0.227,0-0.412,0.184-0.412,0.411v1.648C10.762,14.81,10.947,14.993,11.174,14.993 M3.759,13.346h4.943c0.227,0,0.412-0.184,0.412-0.412c0-0.228-0.185-0.411-0.412-0.411H3.759c-0.227,0-0.412,0.184-0.412,0.411C3.347,13.162,3.532,13.346,3.759,13.346 M3.759,14.993h3.295c0.228,0,0.412-0.184,0.412-0.411S7.282,14.17,7.055,14.17H3.759c-0.227,0-0.412,0.185-0.412,0.412S3.532,14.993,3.759,14.993 M14.881,5.932H1.7c-0.455,0-0.824,0.369-0.824,0.824v9.886c0,0.454,0.369,0.823,0.824,0.823h13.181c0.455,0,0.823-0.369,0.823-0.823V6.755C15.704,6.301,15.336,5.932,14.881,5.932M14.881,16.642H1.7v-5.767h13.181V16.642z M14.881,8.403H1.7V6.755h13.181V8.403z M18.176,2.636H4.995c-0.455,0-0.824,0.37-0.824,0.824v1.236c0,0.228,0.185,0.412,0.412,0.412c0.228,0,0.412-0.184,0.412-0.412V3.46h13.181v9.886H16.94c-0.228,0-0.412,0.185-0.412,0.412s0.185,0.412,0.412,0.412h1.235c0.455,0,0.824-0.369,0.824-0.824V3.46C19,3.006,18.631,2.636,18.176,2.636" />
                        s{' '}
                      </svg>
                    </div>
                    <p className="text-2xl text-grey-darker font-medium mb-4">
                      Tracking Order Info
                    </p>
                    {order && (
                      <p className="text-grey max-w-xs mx-auto mb-6">
                        <Tooltip placement="topLeft" title={get(data, 'order._id')}>
                          Order Code: <b>{order}</b>.
                        </Tooltip>
                      </p>
                    )}
                    {client && (
                      <p className="text-grey max-w-xs mx-auto">
                        <Tooltip placement="topLeft" title={get(data, 'client._id')}>
                          Client Name: <b>{client}</b>.
                        </Tooltip>
                      </p>
                    )}
                  </div>

                  <Form
                    form={form}
                    name="basic"
                    initialValues={data}
                    className="max-w-2xl mx-auto mb-6"
                    onFinish={onFinish}>
                    <Form.Item label="Tracking Number" name="trackingNumber" rules={requireF}>
                      <Input />
                    </Form.Item>

                    <Form.Item label="Completion Percent" name="percent">
                      <Slider defaultValue={0} step={10} />
                    </Form.Item>

                    <Form.Item label="Status" name="status" className="text-left" rules={requireF}>
                      <Select style={{ width: 120 }}>
                        <Select.Option value="progress">Progress</Select.Option>
                        <Select.Option value="pending">Pending</Select.Option>
                        <Select.Option value="Delivered">Delivered</Select.Option>
                      </Select>
                    </Form.Item>
                    <p className="text-2xl text-grey-darker text-left font-medium mb-4">Lines</p>

                    {lines.length !== 0 && (
                      <table className="min-w-full">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Time
                            </th>

                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Location
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Event
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                              Action
                            </th>
                          </tr>
                        </thead>

                        <tbody className="bg-white">
                          {lines
                            .sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
                            .map((line, index) => {
                              return (
                                <LineItem
                                  {...line}
                                  remove={removeLine}
                                  id={line.id}
                                  isFirst={index === 0}
                                />
                              )
                            })}
                        </tbody>
                      </table>
                    )}

                    <Form
                      name="list"
                      form={formLine}
                      className="max-w-lg mx-auto mb-6 text-left"
                      onFinish={addLine}>
                      <Form.Item label="Date" className="w-fl" name={'date'} rules={requireF}>
                        <DatePicker
                          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                          format="YYYY-MM-DD HH:mm"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Location"
                        className="w-fl"
                        name={'location'}
                        rules={requireF}>
                        <Input />
                      </Form.Item>

                      <Form.Item label="Status" className="w-fl" name={'status'} rules={requireF}>
                        <Input />
                      </Form.Item>

                      <Form.List name="messages">
                        {(fields, { add, remove }) => {
                          return (
                            <div>
                              {fields.map((field, index) => (
                                <Form.Item
                                  label={index === 0 ? 'Messages' : ''}
                                  required={false}
                                  key={field.key}>
                                  <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    noStyle>
                                    <Input
                                      placeholder="Messages"
                                      style={{ width: '60%', marginRight: 8 }}
                                    />
                                  </Form.Item>
                                  {fields.length > 1 ? (
                                    <MinusCircleOutlined
                                      className="dynamic-delete-button"
                                      onClick={() => {
                                        remove(field.name)
                                      }}
                                    />
                                  ) : null}
                                </Form.Item>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    add()
                                  }}
                                  style={{ width: '60%' }}>
                                  Add Message field
                                </Button>
                              </Form.Item>
                            </div>
                          )
                        }}
                      </Form.List>

                      <Button type="primary" htmlType="submit" className="text-center m-auto">
                        <PlusOutlined /> Save Line
                      </Button>
                    </Form>

                    <Form.Item>
                      <Button type="primary" loading={submitting} htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </PageWrapper>
  )
}

export default TrackingAdminPage
