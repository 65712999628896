import React from 'react'
import NavBar from './NavBar'
import FrontImage from './FrontImage'
import Stats from './Stats'
import MainBody from './MainBody'
import MiddleImage from './MiddleImage'
import BottomBody from './BottomBody'
import Products from './Products'
import ContactUs from './ContactUs'
import Footer from './Footer'
import NewsLetter from './NewsLetter'
import Tracking from './Tracking'
import CookieConsent from 'react-cookie-consent'

function Main(props) {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto max-w-screen-xl home">
        <NavBar />
        <FrontImage />
        <Stats />
        <MainBody />
        <NewsLetter />
        <Tracking />
        <MiddleImage />
        <BottomBody />
        <Products />
        <ContactUs />
        <Footer />
        <CookieConsent debug={true}>
          This website uses cookies to enhance the user experience.
          {/*<span style={{ fontSize: '10px' }}>This bit of text is smaller :O</span>*/}
        </CookieConsent>
      </div>
    </div>
  )
}

export default Main
