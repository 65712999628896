import React, { useState } from 'react'
import logoMondiale from '../img/MondialeGroupLogo.png'

function Header(props) {
  return (
    <div
      style={{
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <img
        src={logoMondiale}
        alt="logo"
        style={{
          width: '70%',
          height: '70%',
          margin: 'auto',
          flex: 1,
          maxWidth: '500px',
        }}
      />
    </div>
  )
}

export default Header
