import React, { useState } from 'react'
import { Button, Checkbox, Form, Input, Select } from 'antd'
import { navigate } from '@reach/router'
import ProductLine from './ProductLine'
import useFetch from '../modules/useFetch'

export function CustomInput({ label, name, require = true, placeholder, type = 'input' }) {
  return (
    <div className="lg:inline-block lg:pr-5 w-full block mb-4">
      <Form.Item
        label={label}
        name={name}
        className="block text-grey-darker text-sm font-bold mb-2"
        rules={[
          require && {
            required: true,
            message: 'This Field is Required!',
          },
        ]}>
        <Input
          placeholder={placeholder || label}
          className="appearance-none border rounded w-full py-2 px-3 text-grey-darker"
        />
      </Form.Item>
    </div>
  )
}

function ContactUs(props) {
  const [submitting, setSubmitting] = useState(false)
  const [lines, setLines] = useState([])
  const [form] = Form.useForm()

  const { response: data } = useFetch('/products')
  const { response: country } = useFetch('https://restcountries.eu/rest/v2/all', {}, true)

  async function onFinish(values) {
    setSubmitting(true)

    const data = { ...values, lines }
    const response = await fetch('api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    setSubmitting(false)
    form.resetFields()
    navigate('/success/contact')
    console.log(response)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <div className=" flex  mt-16 font-sans antialiased bg-grey-lightest" id="enquiry">
        <div className="ml-4">
          <h5 className="text-3xl leading-6 font-medium text-gray-900">HOW TO ORDER</h5>
          <div className="w-full bg-grey-lightest">
            <div className="container mx-auto py-8">
              <div className="w-5/6 mx-auto bg-white rounded shadow">
                <div className="py-4 px-8 text-black text-xl border-b border-grey-lighter">
                  <p className="mt-2 text-sm leading-6 text-gray-600">
                    Availability & Pricing is currently highly variable per shipment due to daily
                    variances in raw materials, currency fluctuations and freight costs.
                  </p>
                  <p className="mt-2 text-sm leading-6 mt-2 text-gray-600">
                    To place an order or enquire about availability of products, please complete the
                    below form.
                  </p>
                </div>

                <div className="py-4 px-8">
                  <CustomInput
                    {...{ label: 'Company Name', name: 'companyName', require: false }}
                  />

                  <CustomInput {...{ label: 'Contact Name', name: 'contactName' }} />

                  <CustomInput {...{ label: 'Email Address', name: 'email' }} />

                  <CustomInput {...{ label: 'Phone/Mobile', name: 'phone' }} />

                  <div className="lg:inline-block lg:pr-5 lg:w-1/2 block mb-4">
                    <Form.Item
                      label="Country"
                      rules={[{ required: true, message: ' This Field is Required!' }]}
                      name="country"
                      className="block text-grey-darker text-sm font-bold mb-2">
                      <Select placeholder="Select a Country" allowClear>
                        {(country || [])
                          .map((i) => ({ code: i.alpha2Code, name: i.name }))
                          .map((i) => (
                            <Select.Option
                              key={Math.random()}
                              className=" bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                              value={i.name}>
                              {i.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="lg:inline-block lg:pr-5 lg:w-1/2 block mb-4">
                    <Form.Item name="isFirstResponder" valuePropName="checked">
                      <Checkbox>First Responder / Medical Practicioner</Checkbox>
                    </Form.Item>
                  </div>

                  <CustomInput
                    {...{ label: 'Notes', name: 'note', type: 'textArea', require: false }}
                  />
                  <ProductLine {...{ lines, setLines, data }} />

                  <div className="flex items-center justify-between mt-8">
                    <Button type="primary" htmlType="submit">
                      Send
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ContactUs
