import React from 'react'
import mainImg from '../img/nurse-and-kid.jpg'

function FrontImage(props) {
  return (
    <div
      className="w-full bg-gray-500 rounded bg-contain lg:h-r40"
      style={{
        backgroundImage: `url(${mainImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundBlendMode: 'multiply',
        backgroundPosition: 'center',

      }}>
      <div className="flex flex-col">
        <h1 className="font-extrabold md:text-4xl text-white mx-auto font-sans tracking-wider mt-64 md:mt-r20">
          EMERGENCY MEDICAL SUPPLIES
        </h1>
        <h3 className="font-extrabold md:text-2xl text-white mx-auto font-sans tracking-wider">
          Protect Yourself Against Covid-19
        </h3>
      </div>
    </div>
  )
}

export default FrontImage
