import React from 'react'
import TrackingPage from './TrackingPage'
import Header from './Header'

function TrackingStand({ trackingNumber }) {
  return (
    <>
      <Header className="m-16" />

      <div className="mx-auto my-3 max-w-lg rounded overflow-hidden shadow-lg p-6">
        <TrackingPage {...{ trackingNumber }} />
      </div>
    </>
  )
}

export default TrackingStand
