import React from 'react'
import ProductCard from './ProductCard'
import gowns from '../img/gowns.png'
import goggles from '../img/goggles.png'
import handSanitizer from '../img/handSanitizer.png'
import irThermometer from '../img/irThermometer.png'
import ventilators from '../img/ventilators.jpg'
import covidTest from '../img/covidTest.png'
import ProductModal from './ProductModal'
import { Descriptions } from 'antd'

function Products(props) {
  return (
    <div>
      <div className="sm:flex flex-wrap items-center">
        <ProductModal {...{ img: gowns, title: 'Surgical Gowns', subTitle: '<< Click for Details >>'}}>
          <Descriptions>
            <Descriptions.Item label="Disposable PE Gowns">
              <i>SKU: MED-PPE-DGW</i><br />
              PP+PE<br />
              S-3XL as your required Color:Blue<br />
              120*140cm, 10 pcs/bag(Squeezed vacuum), 100pcs/ctn<br />
              Feature: Light,Nonabsorbent /Antibacterial /Degradable/ Convenient Disposable /Anti- static /Anti Dus<br />
              30g/pc
            </Descriptions.Item>
          </Descriptions>
          <Descriptions>
            <Descriptions.Item label="Standard Surgical Gowns (SSMMS)">
              <i>SKU: MED-PPE-SGW</i><br />
              Made from high quality SMS/SMMS/SSMMS (EN13795);<br />
              CE Standard Surgical gown,<br />
              S:115X137cm, M:120X140cm, L:125X145cm, XL:130x150:<br />
              Sterile and non sterile available Ultrasonic seam instead of single/double needle line;<br />
              45-50g/pc, 10pcs/pe bag, 100pcs/ctn,<br />
              PE Bag or Sterilization Paper Plastic Bag
            </Descriptions.Item>
          </Descriptions>
        </ProductModal>

        <ProductModal {...{ img: goggles, title: 'Safety Glasses', subTitle: '<< Click for Details >>' }}>
          <Descriptions>
            <Descriptions.Item label="Standard Safety Glasses">
              <b>SKU: MED-PPE-SSG</b><br />
              Standard plastic safety work glasses.<br />
              Usage: Safety Glasses against dust; Material: Material:PC+PC<br />
              100pcs/ctn, 4kgs/ctn, 50*38*39cm/ctn
            </Descriptions.Item>
          </Descriptions>
          <Descriptions>
            <Descriptions.Item label="Laboratory Safety Goggles">
              <i>SKU: MED-PPE-LSG</i><br />
              Laboratory Chemical Work Goggles; <br />
              Usage: Medical glasses Face Shape; Match: Unisex; Material: PC+PE<br />
              100pcs/ctn, 10kgs/ctn, 50*38*39cm/ctn
            </Descriptions.Item>
          </Descriptions>
        </ProductModal>

        <ProductModal {...{ img: handSanitizer, title: 'Hand Sanitiser', subTitle: '<< Click for Details >>' }}>
          <Descriptions>
            <Descriptions.Item label="75% Alcohol Hand Based Hygiene Disinfection Spray (100ml)">
              <i>SKU: MED-SAN-HS1</i><br />
              75% alcohol Sterilization,<br />
              Medical/common use: Kills bacteria and virus Shelf life: 3 years<br />
              Disinfection of skin and articles<br />
              Volume: 100ml<br />
              Features:Antibacterial, Antiseptic, Moisturizing Hand, Skin care, Hand wash 100pcs/ctn, 11.7kgs/ctn, 39*39*15.5cm=0.0236cbm/ctn
            </Descriptions.Item>
          </Descriptions>
          <Descriptions>
            <Descriptions.Item label="75% Alcohol Based hand Sanitizing Gel (300ml)">
              <i>SKU: MED-SAN-HS3</i><br />
              75% Alcohol hand Sanitizing Gel,<br />
              300ml Ingredients: Ethanol 70-75% (W/W) Suitable for hand and hygienic preventive distinfection Expiration date: 2 years<br />
              20pcs/ctn, 6.9kgs/ctn, 33.5*28*18.3cm=0.0172cbm/ctn
            </Descriptions.Item>
          </Descriptions>
        </ProductModal>

        <ProductModal {...{ img: irThermometer, title: 'Thermometers', subTitle: '<< Click for Details >>' }}>
          <Descriptions>
            <Descriptions.Item label="Digital Non-Contact LCD IR Thermometer">
              <i>SKU: MED-EQP-IRT</i><br />
              Digital Non-Contact LCD IR Laser infrared<br />
              High-precision, accuracy ± 0.2° C. <br />
              Automatically shut down if no measurement<br />
              Measurement memory function, it can record 32 sets of measurement data. With low voltage alarm function.<br />
              Temperature compensation function. <br />
              Package size (W*D*H):195*140*75mm Gross Weight: 0.33kg<br />
              1.5V AAA Battery x 2 pcs
            </Descriptions.Item>
          </Descriptions>
          <Descriptions>
            <Descriptions.Item label="Digital Dual Purpose Thermometer">
              <i>SKU: MED-EQP-DHT</i><br />
              Ear And Forehead Digital Medical Infrared Thermometer For Baby Children and Adults Fahrenheit And Celsius Conversion<br />
            </Descriptions.Item>
          </Descriptions>
        </ProductModal>

        <ProductModal {...{ img: ventilators, title: 'Ventilators', subTitle: '<< Click for Details >>' }}>
          <Descriptions>
            <Descriptions.Item label="2000B-ICU Ventilator">
              <i>SKU: MED-EQP-VT1</i><br />
              Eight types of ventilator mode, OCSI<br />
              Ventilation mode:IPPV, SIPPV, SIMV, PEEP, SIGH,MAN,PCV,VCV)<br />
              Pressure time waveform, flow rate-time waveform,flow-volume loop, pressure loop TV:20 - 1500ML;<br />
              Rate:1-120bpm: I:E: 4:1-1:8 Monitoring of oxygen concentration, 21 - 100%<br />
              21 parameters for ventilation monitoring 10 secutiry Aalarm Systems<br />
              Japan's sharp 10.4"" TFT color LCD screen display<br />
              MAC SOLENOID VALVE(USA)<br />
              Pressure sensor (USA) Eletronicproportionalvalve(Germany) Simply respirator, humidifier, compressor CE，<br />
              G.W.:28KG, 1pc/carton<br />
              CE Certificate
            </Descriptions.Item>
          </Descriptions>
        </ProductModal>

        <ProductModal {...{ img: covidTest, title: 'Covid-19 Test Kits', subTitle: '<< Click for Details >>' }}>
          <Descriptions>
            <Descriptions.Item label="COVID-19 Home Test Kit">
              <i>SKU: MED-EQP-CHT</i><br />
              Diagnostic Kit for Antibody IgM/IgG of Novel Coronavirus COVID-19 <br />
              (Colloidal Gold Immunochromatography Assay)<br /><br />

              Testing Time: 10 min<br />
              Specimen: Whole Blood, Serum, Plasma, Capillary Blood<br />
              Specimen Quantity: Whole Blood(Capillary Blood) 20 μL; Serum(Plasma) 10 μL<br />
              Package:  20 Tests / Box<br />
              Storage: 2-30°C<br />
              Warranty Period: 12 months
            </Descriptions.Item>
          </Descriptions>
        </ProductModal>

      </div>
    </div>
  )
}

export default Products
