import React from 'react'
import mainImg from '../img/doctor.jpg'

function MiddleImage(props) {
  return (
    <div
      className=" bg-gray-500 rounded bg-contain lg:h-r30 mt-20"
      style={{
        backgroundImage: `url(${mainImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundBlendMode: 'multiply',
        backgroundPosition: 'center',
      }} id='covid-19'>
      <div className="flex flex-col">
        <h2 className="font-extrabold lg:text-base lg:pl-5 mt-32 ls:mt-r30 sm:text-xl text-white font-sans tracking-wider">
          <ul>
            <li>
              <a href={'https://coronavirus.jhu.edu/map.html'} target="_blank" rel="noopener noreferrer">The latest Numbers from Johns Hopkins University</a>
            </li>
            <li>
              <a href={'https://www.elsevier.com/connect/coronavirus-information-center'} target="_blank" rel="noopener noreferrer">Novel Coronavirus Information Center (Elsevier Connect)</a>
            </li>
            <li>
              <a href={'http://www.onlinejacc.org/content/covid-19-clinical-guidance-global-cardiovascular-clinicians'} target="_blank" rel="noopener noreferrer">COVID-19 Clinical Guidance for Global Cardiovascular Clinicians (JACC)</a>
            </li>
            <li>
              <a href={'https://www.agility.com/insights/COVID19/air-freight/'} target="_blank" rel="noopener noreferrer">Global Air Freight Updates</a>
            </li>
          </ul>
        </h2>
        <h1 className="font-extrabold lg:text-2xl lg:pl-5 mt-32 ls:mt-r30 sm:text-xl text-white font-sans tracking-wider">
          <a
            target={'_blank'}
            rel="noopener noreferrer"
            href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019">
            Find out more from 'WHO' about COVID-19 >>>
          </a>
        </h1>
      </div>
    </div>
  )
}

export default MiddleImage
