import React, { useState } from 'react'
import Header from './Header'
import { Form, Input, Button, Checkbox, Alert } from 'antd'
import authentication from '../img/undraw_authentication_fsn5.png'

function LoginPage(props) {
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const onFinish = (values) => {
    setLoading(true)
    setIsError(false)
    setTimeout(() => {
      setLoading(false)
      setIsError(true)
    }, 1000)
    console.log('Success:', values)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <>
      <Header className="m-16" />
      <div className="mx-auto my-3 max-w-2xl rounded overflow-hidden p-5 shadow-lg">
        <img src={authentication} className="w-full" alt="a platter of cookies" />

        {isError && (
          <div className="mb-5 p-4">
            <Alert
              message="Error"
              description="This is Account doesn't exist. Enter a different account."
              type="error"
              showIcon
            />
          </div>
        )}

        <Form
          className="max-w-lg m-auto"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default LoginPage
