import React from 'react'
import { Button, Form, Input, InputNumber, Select } from 'antd'
import { get } from 'lodash'
import uniqid from 'uniqid'

function LineItem({ line, remove }) {
  console.log(111, { line })
  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        <div className="text-sm leading-5 text-gray-900">{get(line, 'product.name')}</div>
        <div className="text-sm leading-5 text-gray-500">{get(line, 'product.description')}</div>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        {line.quantity}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
        <span
          onClick={() => remove(line.key)}
          className="text-red-600 hover:text-indigo-900 focus:outline-none focus:underline">
          remove
        </span>
      </td>
    </tr>
  )
}

function LineItemForm({ setLines, lines, data }) {
  return (
    <Form
      name="basic"
      onFinish={(values) => {
        console.log(values)
        setLines([
          ...lines,
          {
            ...values,
            product: data.find((i) => i._key === values.product),
            key: uniqid(),
          },
        ])
      }}
      onFinishFailed={(errorInfo) => console.log(errorInfo)}>
      <div className="lg:flex mt-3 ml-3 w:full">
        <Form.Item
          name="product"
          rules={[{ required: true, message: ' This Field is Required!' }]}
          className="block text-grey-darker text-sm font-bold mb-2 pr-4 lg:w-1/2">
          <Select className="text-grey-darker rounded bg-white" placeholder="Select a Product">
            {(data || []).map((i) => (
              <Select.Option
                key={Math.random()}
                className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                value={i._key}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={'quantity'}
          rules={[{ required: true, message: ' This Field is Required!' }]}
          className="flex-1 block text-grey-darker text-sm mr-8 font-bold mb-2">
          <Input
            placeholder={'quantity'}
            className="appearance-none border rounded lg:w-1/2 py-2 px-3 text-grey-darker"
          />
        </Form.Item>
        <div className="flex-2 flex items-center justify-between md:flex md:justify-center md:m-3">
          <Button htmlType="submit" type="primary">
            Add
          </Button>
        </div>
      </div>
    </Form>
  )
}

function ProductLine({ lines, setLines, data }) {
  function remove(key) {
    setLines(lines.filter((i) => i.key !== key))
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Product
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Quantity
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50" />
              </tr>
            </thead>
            <tbody className="bg-white">
              {lines.map((line) => (
                <LineItem {...{ line, remove }} key={Math.random()} />
              ))}
            </tbody>
          </table>
          <LineItemForm {...{ setLines, lines, data }} />
        </div>
      </div>
    </div>
  )
}

export default ProductLine
