import React from 'react'
import Header from './Header'

function PageWrapper(props) {
  return (
    <div className="font-sans bg-grey-lighter flex flex-col min-h-screen w-full">
      <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
        <Header className="m-16" />
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-2/3 px-4 m-auto">{props.children}</div>
        </div>
      </div>
    </div>
  )
}

export default PageWrapper
