import React from 'react'

function ProductCard({ img, title, subTitle, points = [] }) {
  return (
    <div className="flex-1 py-6 px-3">
      <div className="bg-white shadow-xl rounded-lg overflow-hidden">
        <div className="p-4 pt-12">
          <div
            className="bg-cover bg-center h-56 p-4 bg-contain bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${img})` }}
          />
        </div>
        <p className="p-4 text-lg text-gray-900 text-center">{title}</p>
        <p className="uppercase text-sm pb-2 text-gray-600 text-center">{subTitle}</p>
      </div>
    </div>
  )
}

export default ProductCard
