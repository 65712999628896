import React, { useState } from 'react'
import { Result, Button, Layout } from 'antd'
import { navigate } from '@reach/router'
import contactImg from '../img/undraw_emails_6uqr.png'
import newsletterImg from '../img/undraw_mail_2_tqip.png'

import Header from './Header'

const { Content, Footer } = Layout

function Success({ type }) {
  const titleBook = {
    contact: {
      text: 'Your Email has been sent through to our team.',
      img: contactImg,
    },
    newsletter: {
      text: 'We will try our best to keep you updated during this difficult period',
      img: newsletterImg,
    },
  }

  return (
    <>
      <Header className="m-16" />
      <div className="mx-auto my-3 max-w-md rounded overflow-hidden shadow-lg">
        <img src={titleBook[type].img} className="w-full" alt="a platter of cookies" />

        <div className="px-6 py-4">
          <div className="font-bold text-3xl mb-2">Success</div>
          <div className="font-medium text-xl mb-2">Thanks You.</div>
          <p className="text-base text-gray-700">{titleBook[type].text}</p>
        </div>
        <div className="px-6 py-4">
          <Button
            type="primary"
            className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded"
            onClick={() => navigate('/')}>
            Go Back
          </Button>
        </div>
      </div>
    </>
  )
}

export default Success
