import React from 'react'
import Card from './Card'
import ffp2 from '../img/ffp2-mask.png'
import mask from '../img/mask.png'
import glove from '../img/glove.png'
//import handSanitizer from '../img/handSanitizer.png'
function BottomBody(props) {
  return (
    <div className="flex mt-16 antialiased" id="product">
      <div className="ml-4">
        <h5 className="text-3xl leading-6 font-medium text-gray-900">MEDICAL PPE & SUPPLIES</h5>
        <p className="mt-2 text-lg leading-6 mt-5 text-gray-600">
          We are pleased to announce that as of 26 March 2020 our regular stocks will consist of the
          following items:
        </p>

        <div className="md:flex items-center">
          <Card
            {...{
              img: ffp2,
              title: 'FFP2 Respirator Mask',
              subTitle: 'SKU: MED-PPE-N95',
              points: [
                'Material: non-woven / electrostatic filter cotton.',
                'Easy breathing Upgrade electrostatic filter cotton for comfortable and breathable performance',
                'Cool breathing Cold flow breathing valve, breathing refreshing',
                'Wear no burden New upgraded knit band, comfortable and skin friendly',
                'CE Certificate Provided',
              ],
            }}
          />
          <Card
            {...{
              img: mask,
              title: '3 Ply Disposable Face Mask',
              subTitle: 'SKU: MED-PPE-3PY',
              points: [
                'Ear Loop Face mask (3 layers):',
                '\t 1) 25g/m2 spun-bound PP',
                '\t 2) 22g/m2 melt-blown PP(Filter)',
                '\t 3) 25g/m2 spun-bound PP',
                '175mm * 95mm',
                'Blue High BFE/PFE',
                'Adjustable nose piece & Elastic ear loop',
                'CE Certificate Provided',
              ],
            }}
          />
          <Card
            {...{
              img: glove,
              title: 'Surgical Gloves',
              subTitle: 'SKU: MED-PPE-SGL',
              points: [
                'Material : Natural latex',
                'Type: Powdered/powder free',
                'Sizes: \t 6#, 6.5#, 7#, 7.5#, 8#, 8.5#, 9# ',
                'Sterile: Gamma',
                'CE/ISO13485',
                '50 pairs per box',
                'CE Certificate Provided',
                '',
              ],
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default BottomBody
