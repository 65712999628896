import React, { useState } from 'react'
import { Input, Modal } from 'antd'
import packageImg from '../img/undraw_on_the_way_ldaq.png'
import TrackingPage from './TrackingPage'
import useFetch from '../modules/useFetch'

function Tracking(props) {
  const [visible, setVisible] = useState(false)
  const [trackingNumber, setTrackingNumber] = useState('')

  return (
    <div>
      <div className="font-sans antialiased bg-grey-lightest mt-20" id="track">
        <div className="w-full bg-grey-lightest -mb-16">
          <div className="container mx-auto py-8">
            <div className="w-5/6 lg:w-1/2 mx-auto bg-white rounded shadow">
              <div className="py-4 px-8 text-black text-2xl  font-bold text-center">
                Track Your Order
              </div>
              <img src={packageImg} className="w-full" alt="a platter of cookies" />

              <div className="flex py-4 px-8">
                <Input.Search
                  placeholder="Track your Shipment"
                  enterButton="Track Order"
                  onChange={(event) => setTrackingNumber(event.target.value)}
                  size="large"
                  onSearch={() => setVisible(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        width={'50%'}
        destroyOnClose
        title="Order Status"
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}>
        <TrackingPage {...{ trackingNumber }} />
      </Modal>
    </div>
  )
}

export default Tracking
