import React from 'react'
import { Link } from '@reach/router'
import logo from '../img/MondialeGroupLogo.png'

const NavBar = () => (
  <header className=" p-6">
    <div className="flex items-center justify-between flex-wrap">
      <div className="block">
        <a href="/">
          <img className="h-12" src={logo} alt={'Mondiale Group Logo'} />
        </a>
      </div>
      <nav className="block">
        {/*<a href="#home">
          <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">Home</span>
        </a>
        <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">|</span>*/}
        <a href="#about">
          <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">About Us</span>
        </a>
        <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">|</span>
        <a href="#covid-19">
          <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">COVID-19</span>
        </a>
        <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">|</span>
        <a href="#product">
          <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">Medical PPE</span>
        </a>
        <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">|</span>
        <a href="#enquiry">
          <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">How to Order</span>
        </a>
        <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">|</span>
        <a href="#track">
          <span className="inline-block text-gray-800 hover:text-gray-600 mr-4">Track Your Order</span>
        </a>
      </nav>
    </div>
  </header>
)

export default NavBar
