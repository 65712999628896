import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { navigate } from '@reach/router'

function NewsLetter(props) {
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  async function onFinish(values) {
    setSubmitting(true)

    const response = await fetch('api/newsletter', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })

    setSubmitting(false)
    form.resetFields()
    navigate('/success/newsletter')
    console.log(response)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <div className="font-sans antialiased bg-grey-lightest" id="newsletter">
        <div className="w-full bg-grey-lightest -mb-16">
          <div className="container mx-auto py-8">
            <div className="w-5/6 lg:w-1/2 mx-auto bg-white rounded shadow">
              <div className="py-4 px-8 text-black text-xl border-b border-grey-lighter">
                Stay Up to Date
              </div>

              <div className="flex py-4 px-8">
                <div className="pr-2" style={{ width: '90%', marginTop: 1 }}>
                  <Form.Item
                    label="Email"
                    name="email"
                    className="block text-grey-darker text-sm font-bold"
                    rules={[{ required: true, message: 'Please input your Email!' }]}>
                    <Input
                      placeholder={'Email Address'}
                      className="appearance-none border rounded w-full mt-3 py-2 px-3 text-grey-darker"
                    />
                  </Form.Item>
                </div>
                <div className="justify-between pt-12">
                  <Button
                    loading={submitting}
                    htmlType="submit"
                    className="bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">
                    {submitting ? '' : 'Send'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default NewsLetter
