import React from 'react'
import { Button, Modal, Progress, Spin } from 'antd'
import moment from 'moment'
import { navigate } from '@reach/router'
import { upperFirst, get } from 'lodash'
import useFetch from '../modules/useFetch'
import err from '../img/undraw_empty_xct9.png'

function LineItem({ date, location, status, messages = [] }) {
  console.log(date)
  return (
    <tr>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        <div className="text-sm leading-5 text-gray-900">
          {moment(date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY')}
        </div>
        <div className="text-sm leading-5 text-gray-500">
          {moment(date, 'YYYY-MM-DD HH:mm').fromNow()}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        {location}
      </td>
      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
        <div className="text-sm leading-5 text-gray-900">{upperFirst(status)}</div>
        {messages.map((item) => (
          <div className="text-sm leading-5 text-gray-500" key={Math.random()}>
            {item}
          </div>
        ))}
      </td>

    </tr>
  )
}

function TrackingPage({ trackingNumber }) {
  const { response: data, error, loading } = useFetch(`/order/${trackingNumber}`)

  console.log({ data, error })

  return (
    <Spin tip="Loading..." spinning={loading}>
      {get(data, 'status') === 'failed' ? (
        <span>
          <img src={err} className="w-full" alt="a platter of cookies" />

          <div className="px-6 py-4 text-center">
            <div className="font-bold text-3xl mb-2">Sorry</div>
            <div className="font-medium text-xl mb-2">The Tracking Number is Invalid.</div>
            <div className="font-bold text-xl text-red-500 mb-2">
              Tracking Number : {trackingNumber}
            </div>
            <p className="text-base text-gray-700">
              Our Tracking system was unable to find this tracking number.
            </p>
          </div>
        </span>
      ) : (
        <>
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
            Tracking
          </h2>
          <div className="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
            <svg
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                clip-rule="evenodd"
              />
              <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
            </svg>
            <span className="mr-3">Order Number : {trackingNumber}</span>
          </div>
          <Progress percent={get(data, 'percent', 50)} format={(e) => ''} />

          <h2 className="text-md font-bold mt-6 mb-6 leading-7 text-gray-900 sm:text-xl sm:leading-9 sm:truncate">
            Details
          </h2>
          <div className="flex flex-col">
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Time
                      </th>

                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Location
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                        Event
                      </th>
                    </tr>
                  </thead>
                  `
                  <tbody className="bg-white">
                    {get(data, 'list', []).map((t) => (
                      <LineItem {...t} key={Math.random()} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </Spin>
  )
}

export default TrackingPage
