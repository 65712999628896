import React, { useState } from 'react'

function useFetch(url, options, isOut) {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  React.useEffect(() => {
    const doFetch = async () => {
      setLoading(true)
      try {
        const res = await fetch(isOut ? url : `/api${url}`, options)
        const json = await res.json()
        setResponse(json)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }
    doFetch()
  }, [])
  return { response, error, loading }
}

export default useFetch
