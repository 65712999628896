import React from 'react'

function MainBody(props) {
  return (
    <div className="flex mt-16" id="about">
      <div className="ml-4">
        <h5 className="text-3xl leading-6 font-medium text-gray-900">ABOUT US</h5>
        <p className="mt-2 text-lg leading-6 mt-5 text-gray-600">
          MONDIALE, (<a href={'https://www.mondiale.com'} target="_blank" rel="noopener noreferrer">www.mondiale.com</a>) a Singaporean headquartered group, offers a range of global fabrication,
          procurement and supply services to the energy and industrial sectors.
        </p>

        <p className="mt-2 text-lg leading-6 mt-5  text-gray-600">
          The impacts of COVID-19 have and continue to be, far reaching. As a result of this extraordinary situation, many health care institutions, government agencies and industry partners have asked us to assist in
          aleviating the supply chain difficulties in obtaining quality certified emergency supplies of medical PPE.
        </p>

        <p className="mt-2 text-lg leading-6 mt-5 text-gray-600">
          With the demand increasing exponentially, MONDIALE is currently making weekly shipments to customers and local stockist around the world.  All products are certified and available for order in bulk directly from MONDIALE or alternatively from local stockist (where available).
        </p>

        <p className="mt-2 text-lg leading-6 mt-5 text-gray-600">
          <b>Please note that front line Health Professionals have priority in every shipment that is made.</b>
        </p>
      </div>
    </div>
  )
}

export default MainBody
